<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<portauthority-modals
			:modelName="modelName"
		></portauthority-modals>
	</div>
</template>

<script>
import portauthorityActions from './PortauthorityActions';
import portauthorityModals from './PortauthorityModals.vue';

export default {
	name: 'portauthority-grid',
	components: {
		'portauthority-modals': portauthorityModals
	},
	data() {
		return {
			modelName: 'portauthority',
			actions: portauthorityActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
